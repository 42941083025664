<template>
  <div class="container app" id="app">
    <div v-if="accesstoken == ''" class="row app-one">
      <main class="login-form">
        <div class="cotainer">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">Wautique Panel</div>
                <div class="card-body">
                  <div class="form-group row">
                    <label
                      for="email_address"
                      class="col-md-4 col-form-label text-md-right"
                      >Kullanıcı Adı</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="email_address"
                        class="form-control"
                        name="email-address"
                        required=""
                        autofocus=""
                        v-model="kullanici"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="password"
                      class="col-md-4 col-form-label text-md-right"
                      >Şifre</label
                    >
                    <div class="col-md-6">
                      <input
                        type="password"
                        id="password"
                        class="form-control"
                        name="password"
                        required=""
                        v-model="sifre"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 offset-md-4">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      @click="Login"
                    >
                      Giriş
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div v-else class="row app-one">
      <div v-if="isShow" class="col-md-4 side">
        <div class="side-one">
          <!-- Heading -->
          <div class="row heading">
            <div class="col-md-12 p0" style="text-align: center">
              <button
                class="btn btnfont"
                :class="[item.isactive ? item.renk : 'btn-secondary']"
                v-for="item in ustetiketler"
                :key="item.etiket"
                @click="UstEtiket(item.etiket)"
              >
                {{ item.etiket == "" ? "YOK" : item.etiket }}
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(-6)"
                type="button"
                class="btn btn-primary"
              >
                6S
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(-12)"
                type="button"
                class="btn btn-primary"
              >
                12S
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(1)"
                type="button"
                class="btn btn-primary"
              >
                1G
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(3)"
                type="button"
                class="btn btn-primary"
              >
                3G
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(7)"
                type="button"
                class="btn btn-primary"
              >
                7G
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(15)"
                type="button"
                class="btn btn-primary"
              >
                15G
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="Gorusmeler(30)"
                type="button"
                class="btn btn-primary"
              >
                30G
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="GetReport()"
                type="button"
                class="btn btn-warning"
              >
                R
              </button>
            </div>
            <div class="col-md-2 heading-avatar">
              <button
                @click="showModalManuel = true"
                type="button"
                class="btn btn-success"
              >
                M
              </button>
            </div>
          </div>
          <!-- Heading End -->
          <!-- SearchBox -->
          <div class="row searchBox">
            <div class="col-md-12 searchBox-inner">
              <div class="form-group has-feedback">
                <input
                  id="searchText"
                  type="text"
                  class="form-control"
                  name="searchText"
                  placeholder="90546000000 yada metin"
                  @keyup.enter.exact="GorusmeAra"
                  v-model="searchinput"
                />
                <span
                  class="glyphicon glyphicon-search form-control-feedback"
                ></span>
              </div>
            </div>
          </div>
          <!-- Search Box End -->
          <!-- sideBar -->
          <div v-if="gorusmeler.length > 0" class="row sideBar">
            <div class="row sideBar-body">
              Toplam : {{ gorusmeler.length }} Adet
            </div>
            <div
              v-for="(item, M) in gorusmeler"
              :key="M"
              class="row sideBar-body"
              @click="GorusmeDetay(item)"
            >
              <div class="col-md-9 col-9 sideBar-main">
                <div class="row">
                  <div class="col-md-12 col-12 sideBar-name">
                    <span class="name-meta">{{ item.M }}</span>
                    <br />
                    <span class="heading-online">{{
                      DateToHuman(item.T)
                    }}</span>
                    <br />
                    <span class="heading-online">{{ item.E }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-3 sideBar-main">
                <div class="row">
                  <button
                    v-if="item.S != '' && item.S != 'Sipariş Girildi'"
                    class="btn btnfont"
                    :class="EtiketRenkGetir(item.S)"
                  >
                    {{ item.S }}
                  </button>
                  <button
                    v-else-if="item.S == 'Sipariş Girildi'"
                    class="btn btnfont bordertxt"
                    v-bind:style="{
                      'background-color': getColor(item),
                      'getColor(item)': getColor(item),
                    }"
                  >
                    {{ item.S }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row sideBar" style="padding-left: 25% !important">
            <div class="loader"></div>
          </div>
          <!-- Sidebar End -->
        </div>
      </div>
      <!-- New Message Sidebar End -->
      <!-- Conversation Start -->
      <div class="col-md-8 conversation">
        <!-- Heading -->
        <div class="row heading">
          <div class="col-3 p0">
            <span class="heading-name-meta">{{ gorusmedetay.M }}</span
            ><br />
            <span class="heading-online"
              >{{ gorusmedetay.E }} {{ gorusmedetay.D }}</span
            >
          </div>
          <div class="col-6 p0" style="text-align: center">
            <button
              class="btn btnfont"
              :class="[item.isactive ? item.renk : 'btn-secondary']"
              v-for="item in etiketler"
              :key="item.etiket"
              @click="EtiketEkle(item.etiket)"
            >
              {{ item.etiket == "" ? "YOK" : item.etiket }}
            </button>
          </div>
          <div class="col-3 p0" style="text-align: end">
            <img
              v-if="gorusmedetay.W == '905376783800@c.us'"
              src="@/assets/img/905376783800@c.us.png"
            />
            <img
              v-else-if="gorusmedetay.W == '905423463800@c.us'"
              src="@/assets/img/905423463800@c.us.png"
            />
            <img
              v-else-if="gorusmedetay.W == '905433853175@c.us'"
              src="@/assets/img/905433853175@c.us.png"
            />
            <span v-else class="heading-name-meta">{{ gorusmedetay.W }}</span>
            <button
              @click="GorusmeyiSil(gorusmedetay)"
              class="btn btnfont btn-danger"
            >
              Görüşmeyi Sil
            </button>
          </div>
        </div>
        <sipariscomp
          :musteritel="gorusmedetay.M"
          :wanumber="gorusmedetay.W"
          :lastmessagedate="lastmessagedate"
          :keyevent="keyevent"
          @isOrderPanelActive="isOrderPanelActive = $event"
        />
        <div
          class="d-flex flex-row row overflow-auto wpbg"
          id="conversation"
          :class="isOrderPanelActive ? 'message' : 'messagefull'"
        >
          <div
            v-for="(item, Id) in gorusmedetay.G"
            :key="Id"
            class="row message-body"
            v-on:keydown="keyhandler"
            :tabindex="item.Id"
          >
            <div class="speratorstring" v-if="item.speratr">
              {{ StampToHuman(item.Timestamp) }}
            </div>
            <div class="speratorstringred blink" v-if="item.att != null">
              <kbd v-for="item in item.att" :key="item">
                {{ item }}
              </kbd>
            </div>
            <hr v-if="item.speratr || item.att != ''" />
            <div
              v-if="item.WaId.charAt(0) == 'f'"
              class="col-md-12 message-main-receiver"
            >
              <div class="receiver">
                <a
                  v-if="item.HasMedia"
                  :href="base_url + item.Path"
                  target="_blank"
                  ><img :src="base_url + item.Path" style="width: 200px"
                /></a>
                <a
                  v-if="Check(item)"
                  :href="base_url + FindPath(item)"
                  target="_blank"
                  ><img :src="base_url + FindPath(item)" style="width: 200px"
                /></a>
                <div class="message-text warp">{{ item.Metin }}</div>
                <span class="message-time float-right">
                  {{ StampToHuman(item.Timestamp) }}
                </span>
              </div>
            </div>
            <div v-else class="col-md-12 message-main-sender">
              <div class="sender">
                <a
                  v-if="item.HasMedia"
                  :href="base_url + item.Path"
                  target="_blank"
                  ><img :src="base_url + item.Path" style="width: 200px"
                /></a>
                <a
                  v-if="Check(item)"
                  :href="base_url + FindPath(item)"
                  target="_blank"
                  ><img :src="base_url + FindPath(item)" style="width: 200px"
                /></a>
                <div class="message-text warp">{{ item.Metin }}</div>
                <span class="message-time float-right">
                  {{ StampToHuman(item.Timestamp) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="isMobile"
          @click="isMessagePanelShow = false"
          class="btn btnfont btn-primary"
          style="width: inherit; height: 50px"
        >
          GERİ
        </button>

        <!-- Message Box End -->
      </div>
      <!-- Conversation End -->
    </div>
    <!-- App One End -->

    <div v-if="showModal" id="myModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <span @click="showModal = false" class="close">&times;</span>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">dTarih</th>
              <th scope="col">Adet</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, Gun) in report" :key="Gun">
              <td>{{ OnlyDateToHuman(item.Gun) }}</td>
              <td>{{ item.Adet }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showModalManuel" id="myModalManuel" class="modal">
      <!-- Modal content -->
      <div class="modal-content manuel">
        <span @click="showModalManuel = false" class="close">&times;</span>
        Kaynak Hesap
        <select v-model="manuel.WaNumarasi" class="yuz">
          <option>905376783800</option>
          <option>905423463800</option>
        </select>
        Müşteri Numarası
        <input type="text" v-model="manuel.WaMusteriNo" />
        <br />
        <button @click="ManuelGiris" type="button" class="btn btn-success">
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import sipariscomp from "./components/Siparis";
export default {
  name: "App",
  components: {
    sipariscomp,
  },
  mounted: function () {
    for (let i = 1; i < 32; i++) {
      this.colors.push(Math.floor(Math.random() * 16777215).toString(16));
    }
  },
  data() {
    return {
      base_url: "https://wautique.oskay.org",
      accesstoken: "",
      kullanici: "",
      sifre: "",
      gorusmeler: [],
      gorusmedetay: [],
      searchinput: "",
      etiketler: [],
      ustetiketler: [],
      showModal: false,
      showModalManuel: false,
      report: [],
      isMessagePanelShow: false,
      isOrderPanelActive: true,
      paramsPanel: false,
      keyevent: { key: "" },
      colors: [],
      keywords: ["ptt", "yurtiçi", "yurtici", "kredi", "kart"],
      lastmessagedate: new Date(),
      manuel:{
        WaNumarasi:"",
        WaMusteriNo:""
      }
    };
  },
  methods: {
    getColor(c) {
      if (c.S == "Sipariş Girildi")
        return "#" + this.colors[new Date(c.T).getDate()];
    },
    keyhandler(event) {
      this.keyevent = event;
      /* var text = `${event.key} => ${window.getSelection()}`;
      alert(text);*/
    },
    Check(item) {
      return item.Metin.split("|").length == 3;
    },
    FindPath(item) {
      try {
        var qwaid = item.Metin.split("|")[1];
        var pathitem = this.gorusmedetay.G.filter((x) =>
          x.Path.includes(qwaid)
        );
        return pathitem[0].Path;
      } catch (error) {
        return "";
      }
    },
    EtiketRenkGetir(etiket) {
      var e = this.etiketler.filter((x) => x.etiket == etiket)[0];
      return e.renk;
    },
    UstEtiket(item) {
      this.ustetiketler
        .filter((x) => x.etiket == item)
        .forEach((item) => {
          item.isactive = !item.isactive;
        });
    },
    EtiketEkle(item) {
      console.log(item);
      //  if (this.item == "") return;
      let thisvue = this;
      this.axios({
        method: "get",
        url:
          //waNumarasi, string waMusteriNo, string etiket
          this.base_url +
          "/api/Gorusme/Etiketle?waNumarasi=" +
          this.gorusmedetay.W +
          "&waMusteriNo=" +
          this.gorusmedetay.M +
          "&etiket=" +
          item,
        //  responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          console.log(response.data);
          thisvue.SeciliEtiketTemizle();
          thisvue.etiketler
            .filter((x) => x.etiket == item)
            .forEach((item) => {
              item.isactive = true;
            });
          thisvue.gorusmeler
            .filter(
              (x) =>
                x.W == thisvue.gorusmedetay.W && x.M == thisvue.gorusmedetay.M
            )
            .forEach((x) => {
              x.S = item;
            });
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    Login() {
      let thisvue = this;
      const params = new URLSearchParams();
      params.append("grant_type", "password");
      params.append("username", this.kullanici);
      params.append("password", this.sifre);
      this.axios({
        method: "post",
        url: this.base_url + "/token",
        data: params,
      })
        .then(function (response) {
          thisvue.accesstoken = response.data.access_token;
          thisvue.Gorusmeler(3);
          thisvue.Etiketler(3);
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    SeciliEtiketTemizle() {
      this.etiketler
        .filter((x) => x.isactive)
        .forEach((item) => {
          item.isactive = false;
        });
    },
    Etiketler() {
      let thisvue = this;
      this.axios({
        method: "get",
        url: this.base_url + "/api/etiket",
        //      responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          response.data.forEach((item) => {
            thisvue.etiketler.push({
              etiket: item.Etiket,
              renk: item.Renk,
              isactive: false,
            });
            thisvue.ustetiketler.push({
              etiket: item.Etiket,
              renk: item.Renk,
              isactive: true,
            });
          });
          // thisvue.etiketler = response.data;
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    GetReport() {
      let thisvue = this;
      this.axios({
        method: "get",
        url: this.base_url + "/api/Gorusme/Adetler?l=15",
        //      responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          thisvue.showModal = true;
          thisvue.report = response.data;
          // thisvue.etiketler = response.data;
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    Gorusmeler(limit) {
      let thisvue = this;
      this.axios({
        method: "get",
        url: this.base_url + "/api/Gorusme?limit=" + limit,
        //      responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          thisvue.gorusmeler = [];
          response.data.forEach((item) => {
            var filterStatus = thisvue.ustetiketler.filter(
              (x) => x.etiket == item.S
            )[0];
            if (filterStatus.isactive) thisvue.gorusmeler.push(item);
          });
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    GenerateUuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    ManuelGiris() {
      let thisvue = this;
      let obj = [
        {
          WaNumarasi: this.manuel.WaNumarasi + "@c.us",
          WaMusteriNo: this.manuel.WaMusteriNo + "@c.us",
          Etiket: "Manuel",
          MesajDetaylari: [
            {
              Metin: "Mesaj detayı yok! Manuel giriş yapılmış.",
              WaId: this.GenerateUuidv4(),
              Raw: "",
              Type: null,
              Path: null,
              HasMedia: false,
              TimeStamp: 123456,
            },
          ],
        },
      ];
      this.axios
        .post(this.base_url + "/api/Default/post", obj)
        .then(function (response) {
          thisvue.showModalManuel = false;
          console.log(response);
          thisvue.Gorusmeler(-6)
        })
        .catch(function (error) {
          alert(error);
        });
    },
    GorusmeyiSil(item) {
      if (!confirm("Mesaj silinecek emin misiniz?")) return;
      let thisvue = this;
      this.axios({
        method: "get",
        url:
          this.base_url +
          "/api/gorusme/sil?waNumarasiw=" +
          item.W +
          "&waMusteriNow=" +
          item.M,
        //      responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          thisvue.gorusmeler = [];
          response.data.forEach((item) => {
            var filterStatus = thisvue.ustetiketler.filter(
              (x) => x.etiket == item.S
            )[0];
            if (filterStatus.isactive) thisvue.gorusmeler.push(item);
          });
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    GorusmeDetay(item) {
      let thisvue = this;
      this.SeciliEtiketTemizle();
      this.axios({
        method: "get",
        url:
          this.base_url +
          "/api/gorusme/GorusmeDetay?waNumarasi=" +
          item.W +
          "&waMusteriNo=" +
          item.M,
        //  responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          thisvue.gorusmedetay = response.data;
          var lastdate = null;
          for (let i = 0; i < thisvue.gorusmedetay.G.length; i++) {
            const msgitem = thisvue.gorusmedetay.G[i];
            var dt = new Date(msgitem.Timestamp * 1000);
            thisvue.lastmessagedate = dt;
            if (lastdate != dt.toDateString() || lastdate == null) {
              lastdate = dt.toDateString();
              msgitem.speratr = true;
            } else {
              msgitem.speratr = false;
            }
            msgitem.att = [];
            for (let k = 0; k < thisvue.keywords.length; k++) {
              const keyword = thisvue.keywords[k];

              var mtn = msgitem.Metin.toLowerCase().replace(/ /g, "");
              if (mtn.includes(keyword) && msgitem.WaId.charAt(0) == "f") {
                msgitem.att.push(keyword);
              }
            }
          }
          thisvue.etiketler
            .filter((x) => x.etiket == response.data.S)
            .forEach((item) => {
              item.isactive = true;
            });
          thisvue.isMessagePanelShow = true;
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
      this.secilietiket == "";
    },
    GorusmeAra() {
      let thisvue = this;
      this.axios({
        method: "get",
        url: this.base_url + "/api/gorusme/DorusmeAra?q=" + this.searchinput,
        //    responseType: "application/json",
        headers: {
          Authorization: `Bearer ${thisvue.accesstoken}`,
        },
      })
        .then(function (response) {
          thisvue.gorusmeler = response.data;
        })
        .catch((error) => {
          alert("Giriş Yapılamadı \r\n" + error);
        });
    },
    StampToHuman(stamp) {
      return new Date(stamp * 1000).toLocaleString();
    },
    DateToHuman(date) {
      return new Date(date).toLocaleString();
    },
    OnlyDateToHuman(date) {
      return new Date(date).toLocaleString().replace("00:00:00", "");
    },
  },

  computed: {
    isMobile: function () {
      return window.screen.availWidth < 750;
    },
    isShow: function () {
      if (!this.isMobile) return true;
      if (this.isMobile && !this.isMessagePanelShow) return true;
      else return false;
    },
  },
};
</script>

<style>
@import "assets/style.css";
</style>
