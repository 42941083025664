<template>
  <div class="row heading">
    <div v-if="!isactive">
      <button @click="GizleGoster" class="btn btnfont btn-primary yuz">
        SİPARİŞ PANELİNİ GÖSTER
      </button>
    </div>
    <div
      class="order"
      :style="markagorusmevar ? 'background-color: #ffaabb' : ''"
      v-if="isactive"
    >
      <table>
        <tbody>
          <tr>
            <td>Siparis Tarihi</td>
            <td>
              <input
                :class="isDateDiffrent ? 'btn-warning' : ''"
                style="width: 100%"
                type="date"
                v-model="siparistarihi"
              />
            </td>
            <td></td>
            <td>
              <select
                v-model="secilieskisiparis"
                @change="OldSiparisUi()"
                v-if="eskisiparisler.length > 1"
                class="yuz"
              >
                <option v-for="item in eskisiparisler" :key="item.id">
                  {{ item.Tarih }}
                </option>
              </select>
            </td>
            <td></td>
            <td>
              <button
                style="width: 100%"
                @click="GizleGoster"
                class="btn btnfont btn-warning"
              >
                SİPARİŞ PANELİNİ GİZLE
              </button>
              <button
                @click="SiparisUi({ Tarih: null })"
                class="btn btnfont btn-primary yuz"
              >
                YENİ SİPARİŞ
              </button>
            </td>
          </tr>
          <tr>
            <td>Isim Soyisim <kbd>q</kbd></td>
            <td><input v-model="isimsoyisim" type="text" /></td>
            <td>Alici Adres <kbd>w</kbd></td>
            <td rowspan="2">
              <textarea v-model="adres" style="width: 250px"> </textarea>
            </td>
            <td>İl<kbd>e</kbd></td>
            <td><input v-model="il" type="text" /></td>
          </tr>
          <tr>
            <td>Alici Tel <kbd>t</kbd></td>
            <td><input @blur="replacetel" v-model="tel" type="text" /></td>
            <td></td>
            <td>İlçe <kbd>r</kbd></td>
            <td><input v-model="ilce" type="text" /></td>
          </tr>
          <tr>
            <td>
              <input
                class="yuz"
                style="width: 40px"
                type="number"
                v-model="secili.adet"
                step="1"
              />
              Adet
            </td>
            <td>
              <select multiple class="yuz" v-model="secili.beden">
                <option v-for="item in bedenler" :key="item">
                  {{ item }}
                </option>
              </select>
            </td>
            <td>
              <select multiple class="yuz" v-model="secili.renk">
                <option v-for="item in renkler" :key="item">{{ item }}</option>
              </select>
            </td>
            <td>
              <select multiple class="yuz" v-model="secili.urun">
                <option v-for="item in urunler" :key="item">{{ item }}</option>
              </select>
            </td>
            <td></td>
            <td>
              <button @click="UrunEkle" class="btn btnfont btn-success yuz">
                EKLE
              </button>
            </td>
          </tr>
          <tr>
            <td>Siparis Detay <kbd>s</kbd></td>
            <td colspan="5">
              <textarea v-model="siparisdetay"> </textarea>
            </td>
          </tr>
          <tr>
            <td>Kargo</td>
            <td>
              <select class="yuz" v-model="kargo">
                <option v-for="item in kargolar" :key="item">
                  {{ item }}
                </option>
              </select>
            </td>
            <td>Durum</td>
            <td><input v-model="sonkargodurumu" type="text" /></td>
            <td>Kargo Takip</td>
            <td><input v-model="kargotakip" type="text" /></td>
          </tr>
          <tr>
            <td>Ödeme Tipi</td>
            <td>
              <select class="yuz" v-model="odemetipi">
                <option v-for="item in odemetipleri" :key="item">
                  {{ item }}
                </option>
              </select>
            </td>
            <td>Toplam Ödeme</td>
            <td><input v-model="toplamtutar" type="text" /></td>
            <td colspan="2">
              <button
                @click="SaveOrder"
                :disabled="isSaveDisabled"
                class="btn btnfont btn-success yuz"
              >
                Kaydet
              </button>
              <button @click="SipariSil" class="btn btnfont btn-danger yuz">
                Sipariş SİL
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["keyevent", "musteritel", "lastmessagedate", "wanumber"],
  mounted: function () {
    this.siparistarihi = this.lastmessagedate.toDateInputValue();
    this.GetParams();
  },
  data: () => {
    return {
      // base_url: "https://localhost:44341/",
      base_url: "https://wautique.oskay.org/",
      eskisiparisler: [],
      secilieskisiparis: null,
      isactive: true,
      siparistarihi: "2021-05-25",
      isimsoyisim: "",
      il: "",
      ilce: "",
      adres: "",
      tel: "",
      siparisdetay: "",
      kargo: "",
      kargolar: [],
      kargotakip: "",
      durum: "",
      sonkargodurumu: "",
      urunler: [],
      renkler: [],
      bedenler: [],
      odemetipi: "NAKİT",
      toplamtutar: "",
      odemetipleri: [],
      isSaveDisabled: false,
      secili: {
        urun: [],
        renk: [],
        beden: [],
        adet: 1,
      },
      params: [],
      sipid: 0,
      lmdate: null,
      markagorusmevar: false,
    };
  },
  methods: {
    replacetel() {
      this.tel = this.tel.indexOf("9") == 0 ? this.tel.substring(1) : this.tel;
      this.tel = this.tel.indexOf("+") == 0 ? this.tel.substring(1) : this.tel;
      this.tel = this.tel.indexOf("0") == 0 ? this.tel.substring(1) : this.tel;
      this.tel = this.tel.split(" ").join("");
    },
    GizleGoster() {
      this.isactive = !this.isactive;
      this.$emit("isOrderPanelActive", this.isactive);
    },
    UrunEkle() {
      var sipline = `${this.secili.adet} ADET ${this.secili.beden} BEDEN ${this.secili.urun} ${this.secili.renk}`;
      this.siparisdetay += sipline + "\r\n";
    },
    SelectToInput(event) {
      const selection = window.getSelection().toString();
      if (event.key == "q") this.isimsoyisim += selection;
      else if (event.key == "w")
        this.adres += selection.replace(/(\r\n|\n|\r)/gm, " ");
      else if (event.key == "e") this.il = selection;
      else if (event.key == "s") this.siparisdetay += selection;
      else if (event.key == "r") {
        this.ilce = selection;
        this.GetIl(selection);
      } else if (event.key == "t") {
        this.tel = selection;
        this.replacetel();
      }
    },
    OldSiparisUi() {
      var eski = this.eskisiparisler.filter(
        (x) => x.Tarih == this.secilieskisiparis
      )[0];
      this.SiparisUi(eski);
    },
    SiparisUi(item) {
      if (item.Tarih == null) {
        this.siparistarihi = this.lastmessagedate.toDateInputValue();
        this.isimsoyisim = "";
        this.il = "";
        this.ilce = "";
        this.adres = "";
        this.tel = item.Tel;
        this.siparisdetay = "";
        this.kargo = "";
        this.kargotakip = "";
        this.durum = "";
        this.sonkargodurumu = "";
        this.odemetipi = "NAKİT";
        this.toplamtutar = "";
        this.sipid = 0;
      } else {
        this.siparistarihi = new Date(item.Tarih).toDateInputValue();
        this.isimsoyisim = item.IsimSoyisim;
        this.il = item.Il;
        this.ilce = item.Ilce;
        this.adres = item.AliciAdres;
        this.tel = item.AliciTel;
        this.siparisdetay = item.SiparisDetay;
        this.kargo = item.Kargo;
        this.kargotakip = item.KargoTakip;
        this.durum = item.Durum;
        this.odemetipi = item.OdemeTipi;
        this.toplamtutar = item.ToplamTutar;
        this.sipid = item.id;
        this.sonkargodurumu = item.SonKargoDurumu;
      }
    },
    GetOrder() {
      this.lmdate = this.lastmessagedate.toDateInputValue();
      let thisvue = this;
      this.axios
        .get(this.base_url + "api/siparis?cusnumber=" + thisvue.tel)
        .then(function (response) {
          console.log(response);
          if (response.data.length > 0) {
            thisvue.eskisiparisler = response.data;
            var sip = thisvue.eskisiparisler[thisvue.eskisiparisler.length - 1];
            thisvue.SiparisUi(sip);
          } else {
            thisvue.eskisiparisler = [];
            thisvue.SiparisUi({ Tel: thisvue.tel });
          }
          thisvue.GetOlderConversation();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetOlderConversation() {
      let thisvue = this;
      this.axios
        .get(
          this.base_url +
            "api/gorusme/DigerMarka?waMusteriNo=" +
            thisvue.musteritel
        )
        .then(function (response) {
          console.log(response);
          // eslint-disable-next-line no-debugger
          debugger;
          thisvue.markagorusmevar = response.data.adet > 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetParams() {
      let thisvue = this;
      this.axios
        .get(this.base_url + "api/parametre")
        .then(function (response) {
          thisvue.params = response.data;
          thisvue.bedenler = response.data
            .filter((x) => x.Tip == "BEDEN")
            .map((s) => s.Deger);
          thisvue.urunler = response.data
            .filter((x) => x.Tip == "URUN")
            .map((s) => s.Deger);
          thisvue.renkler = response.data
            .filter((x) => x.Tip == "RENK")
            .map((s) => s.Deger);
          thisvue.odemetipleri = response.data
            .filter((x) => x.Tip == "ODEME")
            .map((s) => s.Deger);
          thisvue.kargolar = response.data
            .filter((x) => x.Tip == "KARGO")
            .map((s) => s.Deger);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetIl(ilce) {
      if (this.il != "") return;
      let thisvue = this;
      this.axios
        .get(this.base_url + "api/adres?ilce=" + ilce)
        .then(function (response) {
          console.log(response);
          thisvue.il = response.data.SehirAdi;
          thisvue.ilce = response.data.IlceAdi;
          thisvue.kargo = response.data.VarsayilanKargo;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    SaveOrder() {
      if (this.isDateDiffrent) {
        if (
          !confirm(
            `Son mesaj tarihi ile sipariş tarihi farklı, yinede kaydetmek istiyor musunuz?`
          )
        ) {
          return;
        }
      }

      let thisvue = this;
      var siparis = {
        IsimSoyisim: this.isimsoyisim,
        AliciAdres: this.adres,
        AliciTel: this.tel,
        Il: this.il,
        Ilce: this.ilce,
        SiparisDetay: this.siparisdetay.replace(/(\r\n|\n|\r)/gm, " | "),
        Kargo: this.kargo,
        Durum: this.durum,
        KargoTakip: this.kargotakip,
        Tarih: this.siparistarihi,
        ToplamTutar: this.toplamtutar,
        OdemeTipi: this.odemetipi,
        Grup: "",
        SonKargoDurumu: "",
        WaNumber: this.wanumber,
        WaKuyrugu: "",
        Mesaj: "",
        KargoSubesi: "",
      };
      if (siparis.ToplamTutar == "") {
        alert("Ödeme alanı boş geçilemez !");
        return;
      }
      thisvue.isSaveDisabled = true;
      this.axios
        .post(this.base_url + "api/siparis", siparis)
        .then(function (response) {
          console.log(response);
          thisvue.isactive = false;
          thisvue.isSaveDisabled = false;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    SipariSil() {
      //   let thisvue = this;
      if (confirm(`${this.sipid} numaralı sipariş silinecek. Emin misiniz?`)) {
        this.axios
          .delete(this.base_url + "api/siparis/DeleteSiparis?id=" + this.sipid)
          .then(function (response) {
            alert(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  computed: {
    isDateDiffrent: function () {
      return this.lmdate != this.siparistarihi;
    },
  },
  watch: {
    keyevent: function (event) {
      this.SelectToInput(event);
    },
    musteritel: function (tel) {
      this.tel = tel.replace("@c.us", "");
      this.replacetel();
      this.GetOrder();
      this.isactive = true;
    },
  },
};
</script>

<style scoped>
.yuz {
  width: 100%;
}
.order {
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  line-height: normal;
}
textarea {
  width: 100%;

  margin-top: 0px;
  margin-bottom: 0px;
  height: 65px;
}
</style>